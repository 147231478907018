<template>
  <div class="shipper">
    <div id="ShipperArea" class="pb-3" style="height: auto">
      <!-- 標題 -->
      <div class="single-page-tag mb-3 noPrint">
        <p class="sp-start sp-text fw-bolder">撿貨/出貨單 
          <a href="https://youtu.be/fw9CFHTMWSU?si=U5WY8VuFIXfCOSx8" target="_blank">
            <img
              class="info-icon ms-1"
              src="@/assets/icon/interrogation.png"
            />
          </a>
        </p>
      </div>
      <!-- 操作 -->
      <div class="row mt-3 px-3 mb-3 noPrint">
        <!-- 勾選 -->
        <div class="col-md-6 d-flex fw-bolder">
          <!-- 撿貨單 -->
          <div class="form-check p-0 me-3">
            <input
              class="form-check-input m-0 me-1"
              type="checkbox"
              id="Picklist"
              v-model="setting[0].selected"
            />
            <label class="form-check-label" for="Picklist">撿貨單</label>
          </div>
          <!-- 出貨明細單 -->
          <div class="form-check p-0 me-3">
            <input
              class="form-check-input m-0 me-1"
              type="checkbox"
              id="Shipper"
              v-model="setting[1].selected"
            />
            <label class="form-check-label" for="Shipper"> 出貨明細單 </label>
          </div>
          <!-- 顯示照片 -->
          <div class="form-check p-0 me-3">
            <input
              class="form-check-input m-0 me-1"
              type="checkbox"
              id="Picture"
              v-model="setting[2].selected"
            />
            <label class="form-check-label" for="Picture"> 顯示照片 </label>
          </div>
          <!-- 顯示直列價格 -->
          <div class="form-check p-0 me-3">
            <input
              class="form-check-input m-0 me-1"
              type="checkbox"
              id="ShowRowPrice"
              v-model="setting[3].selected"
            />
            <label class="form-check-label" for="ShowRowPrice">
              顯示直列價格
            </label>
          </div>
          <!-- 寄件單 -->
          <div class="form-check p-0">
            <input
              class="form-check-input m-0 me-1"
              type="checkbox"
              id="ShippingSlip"
              v-model="setting[4].selected"
            />
            <label class="form-check-label" for="ShippingSlip"> 寄件單 </label>
          </div>
        </div>
        <!-- 按鈕 -->
        <div class="col-md-6 text-end">
          <!-- A4 -->
          <div class="dropdown d-inline-block me-2">
            <button
              class="tw-btn tw-btn-secondary dropdown-toggle"
              type="button"
              id="A4Menu"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              A4
            </button>
            <ul class="dropdown-menu">
              <li class="pointer">
                <a class="dropdown-item" @click="print('a4', 'normal')"
                  >正常列印</a
                >
              </li>
              <li class="pointer">
                <a class="dropdown-item" @click="print('a4', 'compact')"
                  >緊湊列印</a
                >
              </li>
            </ul>
          </div>
          <!-- 標籤紙 -->
          <div class="dropdown d-inline-block me-2">
            <button
              class="tw-btn tw-btn-secondary dropdown-toggle"
              type="button"
              id="Lable"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              標籤紙
            </button>
            <ul class="dropdown-menu">
              <li class="pointer">
                <a class="dropdown-item" @click="print('lable', 'normal')"
                  >正常列印</a
                >
              </li>
              <li class="pointer">
                <a class="dropdown-item" @click="print('lable', 'compact')"
                  >緊湊列印</a
                >
              </li>
            </ul>
          </div>
          <button class="tw-btn tw-btn-secondary me-2" @click="close">
            關閉
          </button>
        </div>
      </div>
      <!-- 內容 -->
      <div id="PrintArea" v-for="(data, index) in billList" :key="data.id">
        <div
          :id="`All${data.id}`"
          class="tw-container page-break mb-2 fw-bolder"
          :class="{ 'need-page-break': index < billList.length - 1 }"
        >
          <div class="text-end">
            <img
              class="shot-icon mb-2 noPrint"
              src="@/assets/icon/camera.png"
              alt="截圖"
              name="shotCamera"
              @click="shot(`All${data.id}`)"
            />
          </div>
          <!-- 對帳明細 -->
          <div :id="`billDetail${data.id}`" class="py-1">
            <img
              class="shot-icon mb-2 noPrint me-3"
              src="@/assets/icon/camera.png"
              alt="截圖"
              name="shotCamera"
              @click="shot(`billDetail${data.id}`)"
            />
            <p class="mb-2 fw-bolder tw-border-start">對帳明細</p>
            <div class="row">
              <div class="col-md-6">
                <p class="mb-2 buyer">買家: {{ data.buyer.user.name }}</p>
                <p class="mb-2">賣場名稱: {{ data.store.name }}</p>
                <p class="mb-2">
                  寄送方式:
                  <span v-if="data.shippingType">{{ data.shippingType }}</span>
                  <span style="color: red" v-else>無</span>
                </p>
              </div>
              <div class="col-md-6">
                <p class="mb-2">
                  付款方式:
                  <span v-if="data.paymentType">{{ data.paymentType }}</span>
                  <span style="color: red" v-else>無</span>
                </p>
                <p>
                  收款狀態:
                  <span style="color: green" v-if="data.paid">已收款</span>
                  <span style="color: red" v-else>未收款</span>
                </p>
              </div>
            </div>
            <hr class="dashed-line" />
          </div>
          <!-- 撿貨單 -->
          <div
            :id="`pickList${data.id}`"
            class="py-1"
            v-if="setting[0].selected"
          >
            <img
              class="shot-icon mb-2 noPrint me-3"
              src="@/assets/icon/camera.png"
              alt="截圖"
              name="shotCamera"
              @click="shot(`pickList${data.id}`)"
            />
            <p class="mb-2 fw-bolder tw-border-start">
              撿貨單 | 帳單編號: {{ data.id }}
            </p>
            <ul>
              <!-- header -->
              <li class="py-2 border-bottom border-top header">
                <!-- 圖片 -->
                <div
                  class="img-block"
                  style="vertical-align: text-top"
                  v-if="setting[2].selected"
                >
                  圖片
                </div>
                <div class="content-block">
                  <div class="row m-0">
                    <!-- 商品資訊 -->
                    <div class="col-4 p-0 ps-2">
                      <!-- 商品名 -->
                      <span>名稱</span>
                    </div>
                    <!-- 其餘資訊 -->
                    <div class="col-8 p-0">
                      <div class="row">
                        <!-- 款式名稱 -->
                        <div class="col-5 p-0 ps-2 d-flex align-items-center">
                          款式
                        </div>
                        <!-- 總數量 -->
                        <div class="col-3 p-0 ps-2 d-flex align-items-center">
                          總數量
                        </div>
                        <!-- 勾選框 -->
                        <div class="col-4 p-0 ps-2 d-flex align-items-center">
                          勾選框
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <!-- body -->
              <li
                class="py-2 border-bottom"
                v-for="body in data.pickListData"
                :key="body[0]"
              >
                <!-- 圖片 -->
                <div
                  class="img-block"
                  style="vertical-align: text-top"
                  v-if="setting[2].selected"
                >
                  <Image
                    :imageHash="body[1].merchandiseImgHash"
                    :lazy="false"
                    size="s"
                    v-if="body[1].merchandiseImgHash"
                    :alt="body[1].merchandiseImgHash"
                  ></Image>
                  <img src="@/assets/other-images/noImg.png" alt="" v-else />
                </div>
                <div class="content-block">
                  <div class="row m-0">
                    <!-- 商品資訊 -->
                    <div class="col-4 p-0 ps-2">
                      <!-- 商品名 -->
                      <p class="text-break mb-2">
                        {{ body[1].merchandiseName }}
                      </p>
                      <p class="text-break" v-if="body[1].storageLocation">
                        {{ body[1].storageLocation }}
                      </p>
                    </div>
                    <!-- 其餘資訊 -->
                    <div class="col-8 p-0">
                      <div
                        class="row"
                        :class="{ 'border-bottom': body[1].length > 1 }"
                        v-for="style in body[1].styles"
                        :key="style[0]"
                      >
                        <!-- 款式名稱 -->
                        <div class="col-5 p-0 ps-2 d-flex align-items-center">
                          {{ style[1].styleName }}
                        </div>
                        <!-- 總數量 -->
                        <div class="col-3 p-0 ps-2 d-flex align-items-center">
                          {{ style[1].totalQuantity }}
                        </div>
                        <!-- 勾選框 -->
                        <div class="col-4 p-0 ps-2 d-flex align-items-center">
                          <div class="checking-stamp-box d-inline-block me-2">
                            撿貨
                          </div>
                          <div class="checking-stamp-box d-inline-block">
                            檢核
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="border-top pt-3 row justify-content-between">
                <p class="col-10 p-0 pe-2" style="color: grey">
                  帳單備註:
                  <span class="text-break" v-if="data.note">{{
                    data.note
                  }}</span>
                  <span v-else>無</span>
                </p>
                <p class="col-2 p-0">總數: {{ data.totalQuantity }}</p>
              </li>
            </ul>
            <hr class="dashed-line" />
          </div>
          <!-- 出貨明細 -->
          <div
            :id="`shipperDetail${data.id}`"
            class="py-1"
            v-if="setting[1].selected"
          >
            <img
              class="shot-icon mb-2 noPrint me-3"
              src="@/assets/icon/camera.png"
              alt="截圖"
              name="shotCamera"
              @click="shot(`shipperDetail${data.id}`)"
            />
            <p class="mb-2 fw-bolder tw-border-start">
              出貨明細 | 帳單編號: {{ data.id }}
            </p>
            <ul>
              <!-- header -->
              <li class="mb-2 py-2 border-top border-bottom header">
                <!-- 圖片 -->
                <div
                  class="img-block"
                  v-if="setting[2].selected"
                  style="vertical-align: text-top"
                >
                  圖片
                </div>
                <!-- 右側內容 -->
                <div class="content-block">
                  <div class="row m-0">
                    <!-- 商品名 -->
                    <div class="col-4">
                      <span>名稱</span>
                    </div>
                    <!-- 訂單資訊 -->
                    <div class="col-8">
                      <div class="row mb-2">
                        <!-- 訂單資訊 -->
                        <div class="col-6 p-0 ps-2">款式</div>
                        <!-- 訂單數量 -->
                        <div class="col-2 p-0 ps-2 d-flex align-items-center">
                          數量
                        </div>
                        <!-- 訂單售價 -->
                        <div class="col-2 p-0 ps-2 d-flex align-items-center">
                          單價
                        </div>
                        <!-- 訂單總價 -->
                        <div class="col-2 p-0 ps-2 d-flex align-items-center">
                          總價
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <!-- body -->
              <li
                class="mb-2 py-2 border-bottom"
                v-for="merchandise in data.shipperData"
                :key="merchandise[0]"
              >
                <!-- 圖片 -->
                <div
                  class="img-block"
                  v-if="setting[2].selected"
                  style="vertical-align: text-top"
                >
                  <Image
                    :imageHash="merchandise[1].merchandiseImgHash"
                    size="s"
                    :lazy="false"
                    v-if="merchandise[1].merchandiseImgHash"
                    :alt="merchandise[1].merchandiseImgHash"
                  ></Image>
                  <img src="@/assets/other-images/noImg.png" alt="" v-else />
                </div>
                <!-- 右側內容 -->
                <div class="content-block">
                  <div class="row m-0">
                    <!-- 商品名 -->
                    <div class="col-4">
                      <span>{{ merchandise[1].merchandiseName }}</span>
                    </div>
                    <!-- 訂單資訊 -->
                    <div class="col-8">
                      <template
                        v-for="buyerAndStoreInfo in merchandise[1]
                          .buyerAndStore"
                        :key="buyerAndStoreInfo[0]"
                      >
                        <!-- 買家 -->
                        <p
                          class="mb-2"
                          style="color: blue"
                          v-if="data.hierarchy > 0"
                        >
                          {{ buyerAndStoreInfo[1].buyer }}@{{
                            buyerAndStoreInfo[1].storeName
                          }}
                        </p>
                        <!-- 其餘資訊 -->
                        <div
                          class="row mb-2"
                          :class="{
                            'border-bottom':
                              buyerAndStoreInfo[1].styles.size > 1,
                          }"
                          v-for="style in buyerAndStoreInfo[1].styles"
                          :key="style[0]"
                        >
                          <!-- 款式 -->
                          <div class="col-6 p-0 ps-2">
                            <p>{{ style[1].styleName }}</p>
                          </div>
                          <!-- 同款式總數 -->
                          <div class="col-2 p-0 ps-2 d-flex align-items-center">
                            {{ style[1].totalQuantity }}
                          </div>
                          <!-- 訂單最低單價 -->
                          <div class="col-2 p-0 ps-2 d-flex align-items-center">
                            {{ style[1].lowerPrice }}
                          </div>
                          <!-- 訂單總價 -->
                          <div class="col-2 p-0 ps-2 d-flex align-items-center">
                            {{ style[1].totalPrice }}
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </li>
              <li class="row pt-3">
                <div class="col-md-6 col-12">
                  <p class="mb-2">總數: {{ data.totalQuantity }}</p>
                  <p class="mb-2 text-break" style="color: grey">
                    帳單備註:
                    <span v-if="data.note">{{ data.note }}</span>
                    <span style="color: grey" v-else>無</span>
                  </p>
                </div>
                <div class="col-md-6 col-12">
                  <!-- 橫列 -->
                  <div v-if="!setting[3].selected">
                    <!-- 沒購物金 -->
                    <span v-if="!data.walletUsed"
                      >應付金額:
                      {{
                        $methods.numberToFixed(
                          data.bodyPrice + data.shippingCost + data.balanceMoney
                        )
                      }}(訂單金額: {{ data.bodyPrice }}，運費:
                      {{ data.shippingCost }}，帳單平衡:
                      {{ data.balanceMoney }})</span
                    >
                    <!-- 有購物金 -->
                    <span v-else
                      >應付金額:
                      {{
                        $methods.numberToFixed(
                          data.bodyPrice +
                            data.shippingCost +
                            data.balanceMoney -
                            data.walletUseAmount
                        )
                      }}(訂單金額: {{ data.bodyPrice }}，運費:
                      {{ data.shippingCost }}，帳單平衡:
                      {{ data.balanceMoney }}，購物金折抵:
                      {{ data.walletUseAmount }})</span
                    >
                  </div>
                  <!-- 直列 -->
                  <div v-else>
                    <ul>
                      <li>運費: {{ data.shippingCost }}</li>
                      <li>訂單金額: {{ data.bodyPrice }}</li>
                      <li>帳單平衡: {{ data.balanceMoney }}</li>
                      <li v-if="data.walletUsed">
                        購物金折抵: {{ data.walletUseAmount }}
                      </li>
                    </ul>
                    <hr />
                    <p style="color: red">
                      應付金額:
                      <span v-if="data.walletUsed">{{
                        $methods.numberToFixed(
                          data.bodyPrice +
                            data.shippingCost +
                            data.balanceMoney -
                            data.walletUseAmount
                        )
                      }}</span>
                      <span v-else>{{
                        $methods.numberToFixed(
                          data.bodyPrice + data.shippingCost + data.balanceMoney
                        )
                      }}</span>
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            <hr class="dashed-line" />
          </div>
          <!-- 寄件單 -->
          <div :id="`shippingSlip${data.id}`" v-if="setting[4].selected">
            <img
              class="shot-icon mb-2 noPrint me-3"
              src="@/assets/icon/camera.png"
              alt="截圖"
              name="shotCamera"
              @click="shot(`shippingSlip${data.id}`)"
            />
            <p class="mb-2 fw-bolder tw-border-start">
              寄件單 | 帳單單號: {{ data.id }}
            </p>
            <div class="row">
              <div class="col-6">
                <p class="mb-1" style="color: blue">寄件人：</p>
                <p class="mb-1">姓名: {{ data.store.shipmentName }}</p>
                <p class="mb-1">地址: {{ data.store.shipmentAddress }}</p>
                <p class="mb-1">電話: {{ data.store.shipmentPhoneNumber }}</p>
                <p class="mb-1">備註: {{ data.store.shipmentNote }}</p>
              </div>
              <div class="col-6">
                <p class="mb-1" style="color: green">收件人：</p>
                <p class="mb-1">姓名: {{ data.recipient }}</p>
                <p class="mb-1">
                  地址:
                  <span v-if="data.recipientPostalCode">
                    ({{ data.recipientPostalCode }})
                    {{
                      data.recipientPostalCodeDetail.City +
                      data.recipientPostalCodeDetail.Area
                    }}</span
                  >{{ data.receivingAddress }}
                </p>
                <p class="mb-1">電話: {{ data.recipientPhoneNumber }}</p>
              </div>
            </div>
          </div>
          <hr
            class="double-dashed-line"
            v-if="billList.length > 1 && index < billList.length - 1"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- 截圖的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="shotModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">截圖結果</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <img :src="shotUrl" alt="" style="height: 100%; width: 100%" />
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="ShotName"
              placeholder="輸入檔名"
              v-model="inputShotName"
            />
            <label for="ShotName">輸入檔名</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success" @click="saveShot">
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 截圖套件
import html2canvas from "html2canvas";

export default {
  data() {
    return {
      // modal
      shotModal: {},
      // data
      serverToken: '',
      storeId: 0,
      billHeaderIds: [],
      billList: [],
      // 操作列勾選選項
      checkList: {
        picklist: true,
        shipper: true,
        picture: true,
        rowPrice: true,
        shippingSlip: true
      },
      shotUrl: '',
      inputShotName: '',
    }
  },
  created() {
    this.initialization()
    this.getPrefer()
    this.getBillInfo()
  },
  mounted() {
    this.createModals(['shotModal'])
  },
  computed: {
    setting() {
      return this.$store.state.Prefer.p_shipper.setting
    },
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
      this.billHeaderIds = this.$route.query.headerIds.split(',')
    },
    // 取得偏好
    getPrefer() {
      this.$store.dispatch('Prefer/getStoreInfo', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken')
      })
    },
    getBillInfo() {
      this.$methods.switchLoading('show')
      const vm = this
      const getBillInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [{
        type: 27,
        ids: this.billHeaderIds,
        methods: '{getBuyer{getUser},getRecipientPostalCodeDetail,getBalanceMoney,getBodyPrice,getStore,getBillBodies{getMerchOrder{getStore,getBuyer{getUser},getMerchandise{getMerchandisePictureLinks},getMerchandiseStyles}}}'
      }]
      this.list = []
      $.ajax({
        type: 'POST',
        async: true,
        url: getBillInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const billInfo = res.data[0].objects
            vm.sortOutData(billInfo)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    // 整理資料
    sortOutData(billInfo) {
      this.billList = []
      billInfo.forEach(item => {
        // 撿貨單資料
        let pickListData = new Map()
        // 出貨單資料
        let shipperData = new Map()
        item.totalQuantity = 0
        item.billBodies.forEach(body => {
          // 計算總數量
          item.totalQuantity += body.quantity
          // 商品圖片
          body.merchandiseImgHash = null
          body.merchOrder.merchandise.merchandisePictureLinks.some(img => {
            if (img.front) return body.merchandiseImgHash = img.imageHash
          })
          // 商品名稱
          body.merchandiseName = body.merchOrder.merchandise.name
          // 款式
          let styleIds = []
          body.style = ''
          body.merchOrder.merchandiseStyles.forEach(style => {
            styleIds.push(style.id)
            body.style += `${style.name} `
          })
          body.styleIds = styleIds.toString()
          // 下單的賣場
          body.storeName = body.merchOrder.store.name
          body.storeId = body.merchOrder.storeId
          // 買家
          body.buyerName = body.merchOrder.buyer.user.name
          body.buyerId = body.merchOrder.buyerId
          // 整理撿貨資料
          this.sortOutPickList(pickListData, body)
          // 整理出貨單資料
          this.sortOutShipper(shipperData, body)
        })
        console.log(pickListData)
        console.log(shipperData)
        item.pickListData = pickListData
        item.shipperData = shipperData
        this.billList.push(item)
      });
      this.$methods.switchLoading('hide')
    },
    // 整理撿貨資料
    sortOutPickList(pickListData, body) {
      // 將同商品的單身放一起
      if (!pickListData.has(body.merchOrder.merchandiseId)) {
        pickListData.set(body.merchOrder.merchandiseId, {
          merchandiseImgHash: null,
          merchandiseName: null,
          storageLocation: null,
          styles: new Map()
        })
      }
      pickListData.get(body.merchOrder.merchandiseId).merchandiseImgHash = body.merchandiseImgHash
      pickListData.get(body.merchOrder.merchandiseId).merchandiseName = body.merchandiseName
      pickListData.get(body.merchOrder.merchandiseId).storageLocation = body.merchOrder.merchandise.storageLocation
      // 將同款式放在一起
      if (!pickListData.get(body.merchOrder.merchandiseId).styles.has(body.styleIds)) {
        pickListData.get(body.merchOrder.merchandiseId).styles.set(body.styleIds, {
          totalQuantity: 0,
          styleName: body.style,
          merchOrders: []
        })
      }
      pickListData.get(body.merchOrder.merchandiseId).styles.get(body.styleIds).totalQuantity += body.quantity
      pickListData.get(body.merchOrder.merchandiseId).styles.get(body.styleIds).merchOrders.push(body)
    },
    // 整理出貨單資料
    sortOutShipper(shipperData, body) {
      // 整理同商品
      if (!shipperData.has(body.merchOrder.merchandiseId)) {
        shipperData.set(body.merchOrder.merchandiseId, {
          merchandiseImgHash: null,
          merchandiseName: null,
          buyerAndStore: new Map()
        })
      }
      shipperData.get(body.merchOrder.merchandiseId).merchandiseImgHash = body.merchandiseImgHash
      shipperData.get(body.merchOrder.merchandiseId).merchandiseName = body.merchandiseName

      // 以 人名@賣場 分類
      const buyerAndStore = `${body.buyerId}@${body.storeId}`
      const buyerAndStoreMap = shipperData.get(body.merchOrder.merchandiseId).buyerAndStore
      if (!buyerAndStoreMap.has(buyerAndStore)) {
        buyerAndStoreMap.set(buyerAndStore, {
          buyer: null,
          storeName: null,
          styles: new Map()
        })
      }
      buyerAndStoreMap.get(buyerAndStore).buyer = body.buyerName
      buyerAndStoreMap.get(buyerAndStore).storeName = body.storeName

      // 整理同款式
      const stylesMap = buyerAndStoreMap.get(buyerAndStore).styles
      if (!stylesMap.has(body.styleIds)) {
        stylesMap.set(body.styleIds, {
          totalQuantity: 0,
          styleName: body.style,
          lowerPrice: body.price,
          totalPrice: 0,
          billBodies: []
        })
      }
      if (stylesMap.get(body.styleIds).lowerPrice > body.price) stylesMap.get(body.styleIds).lowerPrice = body.price
      stylesMap.get(body.styleIds).totalPrice += this.$methods.numberToFixed(body.price * body.quantity)
      stylesMap.get(body.styleIds).totalQuantity += body.quantity
      stylesMap.get(body.styleIds).billBodies.push(body)
    },
    // 截圖
    shot(id) {
      this.$methods.switchLoading('show')
      const vm = this;
      this.shotUrl = ''
      this.inputShotName = ''
      $("[name='shotCamera']").addClass("d-none");
      $(".tw-container").addClass("shot-mode");
      html2canvas(document.getElementById(id), {
        backgroundColor: "#fff",
        useCORS: true,
        scale: 1, // 因為目前截圖元素為 1200，所以比例要設 1，他在手機上才不會寬度成倍數上漲
      }).then((canvas) => {
        let dataURL = canvas.toDataURL("image/png");
        vm.shotUrl = dataURL;
        vm.shotModal.show();
        $("[name='shotCamera']").removeClass("d-none");
        $(".tw-container").removeClass("shot-mode");
        setTimeout(() => {
          vm.$methods.switchLoading('hide')
        }, 500);
      });
    },
    // 儲存截圖
    saveShot() {
      let now = new Date();
      let nowFormat = this.$methods.moment(now).format("YYYYMMDD-HHmmss");
      let alink = document.createElement("a");
      alink.href = this.shotUrl;
      alink.download = this.inputShotName !== "" ? this.inputShotName : nowFormat; //圖片名
      alink.click();
      this.shotModal.hide();
      this.shotUrl = "";
      this.inputShotName = "";
    },
    // 列印
    print(size, status) {
      // 先處理紙張大小
      $('#ShipperArea').removeClass('a4 lable')
      if (size === 'a4') $('#ShipperArea').addClass('a4')
      else $('#ShipperArea').addClass('lable')
      // 再處理列印方式
      if (status === 'compact') {
        $(".tw-container").removeClass("page-break");
      } else {
        $(".tw-container").removeClass("page-break");
        /** 在這會特別設置一個 need-page-break 的 class 是因為
         * 我在一般列印時，因為每筆帳單都有設置 page-break 會導致
         * 最後一張帳單永遠會多出一頁空白頁，這時我就必須過濾掉最後一張帳單
         * 讓他不要套用 page-break 的效果，這樣正常列印就不會出現多一張空白頁的問題
         */
        $(".need-page-break").addClass("page-break");
      }
      let printArea = document.getElementById("PrintArea");
      print(printArea);
    },
    // 關閉
    close() {
      window.close();
    },
  },
}
</script>